import './Projects.css'

function Projects(){

    const styles1 = {
        height: "200px",
        background: `url('/images/blog.jpg') no-repeat center center / cover`
    }

    const styles2 = {
        height: "200px",
        background: `url('/images/movie.jpg') no-repeat center center / cover`
    }

    const styles3 = {
        height: "200px",
        background: `url('/images/todo.jpg') no-repeat center center / cover`
    }

    const styles4 = {
        height: "200px",
        background: `url('/images/list.jpg') no-repeat center center / cover`
    }

    const styles5 = {
        height: "200px",
        background: `url('/images/interative-components.jpg') no-repeat center center / cover`
    }

    const styles6 = {
        height: "200px",
        background: `url('/images/issue-tracker.jpg') no-repeat center center / cover`
    }

    const styles7 = {
        height: "200px",
        background: `url('/images/ufo.jpg') no-repeat center center / cover`
    }

    const styles8 = {
        height: "200px",
        background: `url('/images/weather-app.jpg') no-repeat center center / cover`
    }

    return(
        <>
            <div className='row mt-5'>
                <div className='col-sm-12'>
                    <h2 className='mb-4'>React Projects</h2>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles8}></div>
                        <div className="card-body">
                            <h5 className="card-title">Weather App</h5>
                            <p className="card-text">A simple app which uses the Geoloation API and fetches data from WeatherApi.com.</p>
                            <br/>
                            <a href="https://abemonty-weather-app.netlify.app/" target="_blank" className="btn btn-success" rel="noreferrer">View App</a>
                            <a href="https://github.com/AbeMont/weather-app" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles6}></div>
                        <div className="card-body">
                            <h5 className="card-title">Issue Tracker</h5>
                            <p className="card-text">A React app for tracking issues and emphasizes in form validation. Has other UI logic from component States.</p>
                            <br/>
                            <a href="https://abraham-issue-tracker.netlify.app/" target="_blank" className="btn btn-success" rel="noreferrer">View App</a>
                            <a href="https://github.com/AbeMont/issue-tracker" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles4}></div>
                        <div className="card-body">
                            <h5 className="card-title">Travel List</h5>
                            <p className="card-text">A React app which creates a list of items. You can create, update, and sort out your items.</p>
                            <br/>
                            <a href="https://amazing-crepe-c26de8.netlify.app/" target="_blank" className="btn btn-success" rel="noreferrer">View App</a>
                            <a href="https://github.com/AbeMont/travel-list" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles1}></div>
                        <div className="card-body">
                            <h5 className="card-title">Blog App</h5>
                            <p className="card-text">Create a blog article of your interest! Create, edit, and upload images in this MERN Stack app. </p>
                            <br/>
                            <a href="https://portfolio-blog-project.netlify.app/" target="_blank" rel="noreferrer" className="btn btn-success">View App</a>
                            <a href="https://github.com/AbeMont/blog-react-app" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles2}></div>
                        <div className="card-body">
                            <h5 className="card-title">Movie Search</h5>
                            <p className="card-text">Search information about your favorite movies. This React app uses the The Movie Database API.</p>
                            <br/>
                            <a href="https://movie-search-app-10a4c.firebaseapp.com/" target="_blank" rel="noreferrer" className="btn btn-success">View App</a>
                            <a href="https://github.com/AbeMont/video-list-react-app" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles3}></div>
                        <div className="card-body">
                            <h5 className="card-title">Todo List</h5>
                            <p className="card-text">A simple MERN app to keep track of tasks. This excercise taught me CRUD on how to create an API using Express.js and interact with MongoDB.</p>
                            <a href="https://elated-khorana-531493.netlify.app" target="_blank" rel="noreferrer" className="btn btn-success">View App</a>
                            <a href="https://github.com/AbeMont/todo-react-app" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-5'>
                <div className='col-sm-12'>
                    <h2 className='mb-4'>Frontend Projects (Vanilla HTML, CSS, JS)</h2>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles5}></div>
                        <div className="card-body">
                            <h5 className="card-title">UI Component Library</h5>
                            <p className="card-text">A component library with interative features to display my skills in HTML, CSS, & JS.</p>
                            <br/>
                            <a href="https://lucent-tartufo-11cdff.netlify.app/" target="_blank" rel="noreferrer" className="btn btn-success">View App</a>
                            <a href="https://github.com/AbeMont/interactive-components" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles7}></div>
                        <div className="card-body">
                            <h5 className="card-title">UFO</h5>
                            <p className="card-text">Take control of a UFO! This project uses vanilla HTML, CSS, and JS to animate the flying saucer from html buttons.</p>
                            <br/>
                            <a href="https://ufo-project.netlify.app/" target="_blank" rel="noreferrer" className="btn btn-success">View App</a>
                            <a href="https://github.com/AbeMont/ufo" className='github-link' target='_blank' rel="noreferrer">
                                <i class="fa fa-5 fa-github" aria-hidden="true"></i>
                                View Repo
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='row mt-5'>
                <div className='col-sm-12'>
                    <h2 className='mb-4'>Component Library</h2>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles1}></div>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="https://portfolio-blog-project.netlify.app/" target="_blank" className="btn btn-success">Go somewhere</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles2}></div>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="https://movie-search-app-10a4c.firebaseapp.com/" target="_blank" className="btn btn-success">Go somewhere</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card bg-secondary text-white mb-3">
                        <div className="img-block" style={styles3}></div>
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="https://elated-khorana-531493.netlify.app" target="_blank" className="btn btn-success">Go somewhere</a>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Projects;